<template>
  <div class="container-fluid align-items-center justify-content-center">
    <div class="col col-12 headline">
      <h1 class="colorfull">Beantrage deine Google-Daten</h1>
    </div>
    <!-- Schritt 1 -->
    <div class="row justify-content-center align-content-center myCard">
      <div class="col-12 Schritt"><h3>1. Schritt</h3></div>
      <div class="col-12 col-md-8">
        <div class="img" :style="{ backgroundImage: 'url(' + image1 + ')' }"></div>
      </div>
      <div class="col-12 col-md-4 textfenster">
        <p>Rufe folgende Seite auf, um die benötigten Daten auszuwählen <a href="https://takeout.google.com/">takeout.google.com.</a></p>
        <p>Hebe anschließend die Auswahl aller Produkte auf,
          indem du auf "Auswahl aufheben" klickst.</p>
      </div>
    </div>
    <!-- Schritt 2 -->
    <div class="row justify-content-center align-content-center myCard">
      <div class="col-12 Schritt"><h3>2. Schritt</h3></div>
      <div class="col-12 col-md-8">
        <div class="img" :style="{ backgroundImage: 'url(' + image2 + ')' }"></div>
      </div>
      <div class="col-12 col-md-4 textfenster">
        <p>Scrolle nach unten bis zu dem Punkt "Meine Aktivitäten" und wähle diesen aus.</p>
        <p>Hier findest du alle Daten, welche zu deinem Google-Suchverlauf existieren und
        noch andere interessante Daten, wie z.B. deinen Youtube Verlauf. </p>
      </div>
    </div>
    <!-- Schritt 3 -->
    <div class="row justify-content-center align-content-center myCard">
      <div class="col-12 Schritt"><h3>3. Schritt</h3></div>
      <div class="col-12 col-md-8">
        <div class="img" :style="{ backgroundImage: 'url(' + image3 + ')' }"></div>
      </div>
      <div class="col-12 col-md-4 textfenster">
        <p>Bei "Alle Aktivitäten eingeschlossen" kannst du festlegen,
          welche Daten du herunterladen möchtest.
          Für die Anwendung benötigst du ausschließlich die "Google Suche".</p>
          <p>Schau dir aber ruhig noch weitere Daten an, die Google von dir sammelt.</p>
      </div>
    </div>
    <!-- Schritt 4 -->
    <div class="row justify-content-center align-content-center myCard">
      <div class="col-12 Schritt"><h3>4. Schritt</h3></div>
      <div class="col-12 col-md-8">
        <div class="img" :style="{ backgroundImage: 'url(' + image4 + ')' }"></div>
      </div>
      <div class="col-12 col-md-4 textfenster">
        <p>Wenn du alle Kategorien ausgewählt hast,
          scrolle einfach nach ganz unten.</p>
        <p>Fahre fort, indem du auf "Nächster Schritt" klickst.</p>
      </div>
    </div>
    <!-- Schritt 5 -->
    <div class="row justify-content-center align-content-center myCard">
      <div class="col-12 Schritt"><h3>5. Schritt</h3></div>
      <div class="col-12 col-md-8">
        <div class="img" :style="{ backgroundImage: 'url(' + image5 + ')' }"></div>
      </div>
      <div class="col-12 col-md-4 textfenster">
        <p>Um den Export abzuschließen, sorge dafür, dass "Einmal exportieren" ausgewählt ist.</p>
        <p>Klicke anschließend auf "Export erstellen" und lade deine Daten herunter,
          sobald diese verfügbar sind. Das sollte bereits nach wenigen Minuten der Fall sein.</p>
      </div>
    </div>
    <!-- Schritt 6 -->
    <div class="row justify-content-center align-content-center myCard">
      <div class="col-12 Schritt"><h3>6. Schritt</h3></div>
      <div class="col-12 col-md-6">
        <div class="img" :style="{ backgroundImage: 'url(' + image6 + ')' }"></div>
      </div>
      <div class="col-12 col-md-6 textfenster">
        <p>Wenn du deine Daten erfolgreich heruntergeladen hast,
          suche nach der Datei "MeineAktivitäten.html".
          Sie befindet sich im Ordner "Google Suche".</p>
        <p>Du kannst diese Datei im nächsten Schritt auswerten lassen. Wenn du möchtest, dass in Zukunft nicht mehr alles gespeichert wird, überprüfe <a href="https://myaccount.google.com/data-and-privacy">hier</a> deine Einstellungen.</p>
      </div>
    </div>

    <div class="row button">
      <div class="col justify-content-center buttonContainer">
        <button @click="goUpload" class="btn">Daten auswerten</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'Anleitung',
  data() {
    return {
      image1: require('@/assets/media/anleitung/1.png'),
      image2: require('@/assets/media/anleitung/2.png'),
      image3: require('@/assets/media/anleitung/3.png'),
      image4: require('@/assets/media/anleitung/4.png'),
      image5: require('@/assets/media/anleitung/5.png'),
      image6: require('@/assets/media/anleitung/6.png'),
    };
  },
  components: {},
  methods: {
    goUpload() {
      this.$router.push('/upload2');
    },
  },
};
</script>

<style scoped>
.headline {
  padding: 5vh;
}

.button {

  padding: 5vh;
}
.col p {

  /*margin-top: 2vh;*/
}

.myCard {
  border-radius: 10px;
  padding: 3vh;
  margin: 5vh 5vw;
border: 2px solid white;
}

a {
  color: #d9d9d9;
}

.img {
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}

.Schritt {
  margin: 2vh;
}
.textfenster {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid white;
}
.btn {
  color: white;
  border: 3px solid;
  border-image-slice: 1;
  border-radius: 20px;
  border-image-source: linear-gradient(90deg, rgb(213, 59, 185), rgb(32, 144, 169));
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .textfenster {
   margin-top: 3vh;
  }
}
</style>
